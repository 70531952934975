<template>
    <!-- 下拉框 -->
    <aside class="GX7lR -SBnes GsMenu" style="top: 100%; left: 0px; padding-top: 16px; z-index: 1; position: absolute">
        <div class="TlLMR">
            <div class="ckrPL">
                <section class="yFHT9">
                    <a class="QjDI0 I_0O9 btBnB E9j5R" @click="onSignin()" v-if="!isLogin">
                        <span class="WTrSQ">{{ $t('_yhdl._dl') }} | {{ $t('_yhdl._zc') }}</span>
                    </a>
                    <div class="bodse" v-if="isLogin">
                        <div>
                            <img :src="sp_info.avatar" alt="" style="width: 45px; height: 45px; border-radius: 50%" />
                        </div>
                        <div>
                            <h2 class="Qd1JD dls-boa17d">
                                <a class="lcFyR tuUGG RTmqg">{{ sp_info.nickname }}</a>
                            </h2>
                            <div>UID:{{ sp_info.sid }}</div>
                        </div>
                    </div>
                    <div class="bovGa" style="margin-top: 20px">
                        <h2 class="Qd1JD dls-boa17d">
                            <a class="lcFyR tuUGG RTmqg">{{ $t('_zdy._zhsz') }}</a>
                        </h2>
                    </div>
                    <!-- 用户 -->
                    <ul v-if="sp_info.type == 1">
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" class="piRuM">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M11.1877 1.26662C11.7049 1.03678 12.2951 1.03678 12.8123 1.26662L22.4061 5.53055C22.7673 5.69106 23 6.04918 23 6.44437V16.9056C23 17.696 22.5345 18.4122 21.8123 18.7332L12.8123 22.7332C12.2951 22.9631 11.7049 22.9631 11.1877 22.7332L2.18772 18.7332C1.46547 18.4122 1 17.696 1 16.9056V6.44437C1 6.04918 1.23273 5.69106 1.59386 5.53055L11.1877 1.26662ZM3 16.9056L11 20.4612V11.6373L3 7.91115V16.9056ZM4.52642 6.41583L6.97136 7.55468L14.3272 4.12854L12 3.09424L4.52642 6.41583ZM16.7511 5.20584L9.33984 8.65781L12 9.89677L19.4735 6.4158L16.7511 5.20584ZM21 7.91111L13 11.6373V20.4612L21 16.9056V7.91111Z"
                                    fill="#191A1B"></path>
                            </svg>
                            <router-link :to="{ name: 'overview' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('member.overview') }}</router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                        fill="#191A1B"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1864_131">
                                        <rect width="24" height="24" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <router-link :to="{ name: 'orderAll' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('member.orders') }}<span style="color: red">({{ msgCenter.order
                                    }})</span></router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconCreditCardBackOutlined"
                                class="piRuM">
                                <path
                                    d="M18 12C17.4477 12 17 12.4477 17 13C17 13.5523 17.4477 14 18 14H19C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12H18Z"
                                    fill="#191A1B"></path>
                                <path
                                    d="M13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H14C13.4477 14 13 13.5523 13 13Z"
                                    fill="#191A1B"></path>
                                <path
                                    d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H10Z"
                                    fill="#191A1B"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34314 21 0 19.6569 0 18V6ZM22 6V8H2V6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44771 22 6ZM22 10H2V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V10Z"
                                    fill="#191A1B"></path>
                            </svg>
                            <router-link :to="{ name: 'payment' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('member.payment') }}</router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconFreeShippingOutlined"
                                class="piRuM">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2 3C0.895431 3 0 3.89543 0 5V15C0 15.771 0.436269 16.4401 1.07541 16.7739C1.02599 17.0082 1 17.251 1 17.5C1 19.433 2.567 21 4.5 21C6.433 21 8 19.433 8 17.5C8 17.3302 7.98791 17.1633 7.96456 17H14.0354C14.0121 17.1633 14 17.3302 14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 17.3302 20.9879 17.1633 20.9646 17H23C23.5523 17 24 16.5523 24 16V13C24 12.8448 23.9639 12.6916 23.8944 12.5528L21.8944 8.55279C21.725 8.214 21.3788 8 21 8H19V5C19 3.89543 18.1046 3 17 3H2ZM19.9495 15H22V13.2361L20.382 10H19V14.3368C19.353 14.5045 19.6736 14.7296 19.9495 15ZM17 14.0354V5L2 5V15.0505C2.63526 14.4022 3.52066 14 4.5 14C5.45365 14 6.31822 14.3814 6.94949 15H15V15.0505C15.5251 14.5146 16.2212 14.1468 17 14.0354ZM4.5 16C3.67157 16 3 16.6716 3 17.5C3 18.3284 3.67157 19 4.5 19C5.32843 19 6 18.3284 6 17.5C6 16.6716 5.32843 16 4.5 16ZM16 17.5C16 16.6716 16.6716 16 17.5 16C18.3284 16 19 16.6716 19 17.5C19 18.3284 18.3284 19 17.5 19C16.6716 19 16 18.3284 16 17.5Z"
                                    fill="#191A1B"></path>
                            </svg>
                            <router-link :to="{ name: 'address' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('member.address') }}</router-link>
                        </li>
                        <li>
                            <comment-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'message' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('member.message.center') }}<span style="color: red">({{ msgCenter.msg
                                    }})</span></router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                        fill="#191A1B"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1864_131">
                                        <rect width="24" height="24" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <router-link :to="{ name: 'redbag' }" class="KCBwR lcFyR tuUGG RTmqg">{{ $t('_sjhb._sjbt')
                                }}</router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconNewWindow" class="piRuM">
                                <path
                                    d="M14 2C13.4477 2 13 2.44772 13 3C13 3.55228 13.4477 4 14 4H19.0658L7.29289 15.7729C6.90237 16.1634 6.90237 16.7966 7.29289 17.1871C7.68342 17.5776 8.31658 17.5776 8.70711 17.1871L20 5.89419V10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14Z"
                                    fill="#191A1B"></path>
                                <path
                                    d="M5 7C4.44772 7 4 7.44772 4 8V19C4 19.5523 4.44772 20 5 20H16C16.5523 20 17 19.5523 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 20.6569 17.6569 22 16 22H5C3.34315 22 2 20.6569 2 19V8C2 6.34315 3.34315 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z"
                                    fill="#191A1B"></path>
                            </svg>

                            <a href="javascript:" @click="onSignout()" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('signup.sign.out') }}</a>
                        </li>
                    </ul>
                    <!-- 商家 -->
                    <ul v-if="sp_info.type == 2">
                        <li>
                            <dashboard-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeDashboard' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('store.dashboard') }}</router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                        fill="#191A1B"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1864_131">
                                        <rect width="24" height="24" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <router-link :to="{ name: 'storeProductsList' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('store.products.list') }}</router-link>
                        </li>
                        <li>
                            <message-outlined style="font-size: 20px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeProductsReviews' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('reviews.title') }}
                                <span style="color: red">({{ msgCenter.comment }})</span>
                            </router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconCreditCardBackOutlined"
                                class="piRuM">
                                <path
                                    d="M18 12C17.4477 12 17 12.4477 17 13C17 13.5523 17.4477 14 18 14H19C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12H18Z"
                                    fill="#191A1B"></path>
                                <path
                                    d="M13 13C13 12.4477 13.4477 12 14 12H15C15.5523 12 16 12.4477 16 13C16 13.5523 15.5523 14 15 14H14C13.4477 14 13 13.5523 13 13Z"
                                    fill="#191A1B"></path>
                                <path
                                    d="M10 12C9.44772 12 9 12.4477 9 13C9 13.5523 9.44771 14 10 14H11C11.5523 14 12 13.5523 12 13C12 12.4477 11.5523 12 11 12H10Z"
                                    fill="#191A1B"></path>
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M0 6C0 4.34315 1.34315 3 3 3H21C22.6569 3 24 4.34315 24 6V18C24 19.6569 22.6569 21 21 21H3C1.34314 21 0 19.6569 0 18V6ZM22 6V8H2V6C2 5.44772 2.44772 5 3 5H21C21.5523 5 22 5.44771 22 6ZM22 10H2V18C2 18.5523 2.44772 19 3 19H21C21.5523 19 22 18.5523 22 18V10Z"
                                    fill="#191A1B"></path>
                            </svg>
                            <router-link :to="{ name: 'storeOrderList' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.orders') }} <span style="color: red">({{ msgCenter.order }})</span>
                            </router-link>
                        </li>
                        <!-- 金融 -->
                        <!-- <li>
                            <crown-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeFinance' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('_st._jrong') }}
                            </router-link>
                        </li> -->

                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconHeartOutlined" class="piRuM">
                                <g clip-path="url(#clip0_1864_131)">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M0.000244141 8.75C0.000244141 5.02208 3.02232 2 6.75024 2C8.87178 2 10.7634 2.9787 12.0002 4.5073C13.2371 2.9787 15.1287 2 17.2502 2C20.9782 2 24.0002 5.02208 24.0002 8.75C24.0002 12.7009 21.1472 16.1603 18.4721 18.5682C15.7458 21.0223 12.9324 22.6252 12.531 22.8492C12.3988 22.923 12.218 22.9884 12.0002 22.9884C11.7825 22.9884 11.6017 22.923 11.4695 22.8492C11.0681 22.6252 8.25466 21.0223 5.52836 18.5682C2.85324 16.1603 0.000244141 12.7009 0.000244141 8.75ZM6.75024 4C4.12689 4 2.00024 6.12665 2.00024 8.75C2.00024 11.7828 4.25066 14.7272 6.8664 17.0817C8.93547 18.9442 11.0698 20.2922 12.0002 20.8457C12.9307 20.2922 15.065 18.9442 17.1341 17.0817C19.7498 14.7272 22.0002 11.7828 22.0002 8.75C22.0002 6.12665 19.8736 4 17.2502 4C15.3196 4 13.6558 5.15193 12.9128 6.81019C12.7517 7.16983 12.3943 7.4013 12.0002 7.4013C11.6061 7.4013 11.2488 7.16983 11.0877 6.81019C10.3447 5.15193 8.6809 4 6.75024 4Z"
                                        fill="#191A1B"></path>
                                </g>
                                <defs>
                                    <clipPath id="clip0_1864_131">
                                        <rect width="24" height="24" fill="white"></rect>
                                    </clipPath>
                                </defs>
                            </svg>
                            <router-link :to="{ name: 'storeRedbag' }" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('_sjhb._sjbt') }}</router-link>
                        </li>

                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconFreeShippingOutlined"
                                class="piRuM">
                                <path fill-rule="evenodd" clip-rule="evenodd"
                                    d="M2 3C0.895431 3 0 3.89543 0 5V15C0 15.771 0.436269 16.4401 1.07541 16.7739C1.02599 17.0082 1 17.251 1 17.5C1 19.433 2.567 21 4.5 21C6.433 21 8 19.433 8 17.5C8 17.3302 7.98791 17.1633 7.96456 17H14.0354C14.0121 17.1633 14 17.3302 14 17.5C14 19.433 15.567 21 17.5 21C19.433 21 21 19.433 21 17.5C21 17.3302 20.9879 17.1633 20.9646 17H23C23.5523 17 24 16.5523 24 16V13C24 12.8448 23.9639 12.6916 23.8944 12.5528L21.8944 8.55279C21.725 8.214 21.3788 8 21 8H19V5C19 3.89543 18.1046 3 17 3H2ZM19.9495 15H22V13.2361L20.382 10H19V14.3368C19.353 14.5045 19.6736 14.7296 19.9495 15ZM17 14.0354V5L2 5V15.0505C2.63526 14.4022 3.52066 14 4.5 14C5.45365 14 6.31822 14.3814 6.94949 15H15V15.0505C15.5251 14.5146 16.2212 14.1468 17 14.0354ZM4.5 16C3.67157 16 3 16.6716 3 17.5C3 18.3284 3.67157 19 4.5 19C5.32843 19 6 18.3284 6 17.5C6 16.6716 5.32843 16 4.5 16ZM16 17.5C16 16.6716 16.6716 16 17.5 16C18.3284 16 19 16.6716 19 17.5C19 18.3284 18.3284 19 17.5 19C16.6716 19 16 18.3284 16 17.5Z"
                                    fill="#191A1B"></path>
                            </svg>
                            <router-link :to="{ name: 'storeWallet' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.wallet') }}
                            </router-link>
                        </li>
                        <li>
                            <fire-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeAdvertiseList' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('advertise.title') }}
                            </router-link>
                        </li>
                        <li>
                            <alert-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeNotice' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('_xxtz._zntz') }} <span style="color: red">({{ msgCenter.notice }})</span>
                            </router-link>
                        </li>
                        <li>
                            <comment-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeMessage' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.message') }} <span style="color: red">({{ msgCenter.msg }})</span>
                            </router-link>
                        </li>
                        <li v-if="is_moni == 2 || isShare == 2">
                            <share-alt-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeShare' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('setting.invitation') }}
                            </router-link>
                        </li>
                        <li>
                            <setting-outlined style="font-size: 22px; margin-right: 13px; margin-left: -2px" />
                            <router-link :to="{ name: 'storeStore' }" class="KCBwR lcFyR tuUGG RTmqg">
                                {{ $t('store.setting') }}
                            </router-link>
                        </li>
                        <li>
                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none"
                                xmlns="http://www.w3.org/2000/svg" data-testid="svg_IconNewWindow" class="piRuM">
                                <path
                                    d="M14 2C13.4477 2 13 2.44772 13 3C13 3.55228 13.4477 4 14 4H19.0658L7.29289 15.7729C6.90237 16.1634 6.90237 16.7966 7.29289 17.1871C7.68342 17.5776 8.31658 17.5776 8.70711 17.1871L20 5.89419V10C20 10.5523 20.4477 11 21 11C21.5523 11 22 10.5523 22 10V3C22 2.44772 21.5523 2 21 2H14Z"
                                    fill="#191A1B"></path>
                                <path
                                    d="M5 7C4.44772 7 4 7.44772 4 8V19C4 19.5523 4.44772 20 5 20H16C16.5523 20 17 19.5523 17 19V13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13V19C19 20.6569 17.6569 22 16 22H5C3.34315 22 2 20.6569 2 19V8C2 6.34315 3.34315 5 5 5H11C11.5523 5 12 5.44772 12 6C12 6.55228 11.5523 7 11 7H5Z"
                                    fill="#191A1B"></path>
                            </svg>
                            <a href="javascript:" @click="onSignout()" class="KCBwR lcFyR tuUGG RTmqg">{{
                                $t('signup.sign.out') }}</a>
                        </li>
                    </ul>
                    <!-- 24小时客服 -->
                    <!-- <h2 v-if="isLogin" class="fOz0y dls-boa17d">
                            <a class="lcFyR tuUGG RTmqg">{{ $t('_zdy._24zrkf') }}</a>
                          </h2>
                          <ul v-if="isLogin">
                            <li>
                              <CommentOutlined style="margin-right: 13px; font-size: 20px" />
                              <router-link :to="{ name: sp_info.type == 1 ? 'message' : 'storeMessage', query: { customer: 'agent' } }" class="KCBwR lcFyR tuUGG RTmqg"> {{ $t('_n2._jscldd') }} </router-link>
                            </li>
                            <li>
                              <IconWhatsApp style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'" :target="sp_info.whatsapp ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Whats app </a>
                            </li>
                            <li>
                              <IconTelegram style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'" :target="sp_info.telegram ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Telegram </a>
                            </li>
                            <li v-if="sp_info.line">
                              <IconLine style="margin-right: 13px; font-size: 20px" />
                              <a :href="sp_info.line" :target="sp_info.line ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                            </li>
                            <li v-else-if="sp_info.line_qrcode" style="position: relative">
                              <div class="kdiso">
                                <div style="display: flex">
                                  <IconLine style="margin-right: 13px; font-size: 20px" />
                                  <a href="javascript:" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                                </div>
                                <div class="line-box" style="padding: 10px; border: 1px dashed #ccc">
                                  <img :src="sp_info.line_qrcode" alt="line" style="width: 100%" />
                                </div>
                              </div>
                            </li>
                          </ul> -->
                </section>
            </div>
        </div>
        <div class="eLxL5" style="inset: 0px auto auto 40px">
            <div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div>
        </div>
    </aside>

    <!-- 登录注册弹框 -->
    <toast-signin ref="signin" @tokenStatus="onTokenStatus"></toast-signin>
</template>
<script>
import { mapState } from 'vuex'
import ToastSignin from '@/components/signin/ToastSignin.vue'
// CrownOutlined
import { MessageOutlined, DashboardOutlined, CommentOutlined, FireOutlined, AlertOutlined, ShareAltOutlined, SettingOutlined } from '@ant-design/icons-vue'
export default {
    name: "UserMenu",
    components: {
        ToastSignin,
        DashboardOutlined,
        CommentOutlined,
        // CrownOutlined,
        FireOutlined,
        AlertOutlined,
        ShareAltOutlined,
        SettingOutlined,
        MessageOutlined
    },
    emits: ['tokenStatus'],
    data() {
        return {
            //是否登录
            isLogin: false,

            //昵称
            sp_info: {
                avatar: '',
                nickname: '',
                type: 1
            },

            is_moni: 1,
            isShare: 1 //不允许
        }
    },
    created() {
        //自动登录
        this.tokenStatus()
        //获取基本信息
        this.$store.dispatch('base/getBase', {})
    },
    computed: {
        ...mapState({
            msgCenter: (state) => state.base.msgCenter
        })
    },
    methods: {
        onTokenStatus() {
            //登录窗口联动通知上层
            console.log("login...1")
            this.tokenStatus()
            this.$emit('tokenStatus', [])
        },
        tokenStatus() {
            this.isLogin = this.$common.isLogin()
            if (this.isLogin) {
                //获取基本
                let that = this
                this.sp_info = this.$common.getInfo()
                this.$api.home.getService().then((res) => {
                    that.$common.setService(res.data)
                    that.sp_info = that.$common.getInfo()
                    that.is_moni = res.data.is_moni
                })

                //获取分享
                this.$api.home.getShare().then((res) => {
                    that.isShare = res.data.share
                })
                // console.log(this.loginInfo,'loginInfo')
            }
        },
        onSignin() {
            this.$refs.signin.onShow()
        },

        //退出登录
        onSignout() {
            localStorage.removeItem(this.$Const.KEY_TOKEN)
            localStorage.removeItem(this.$Const.KEY_INFO)
            this.isLogin = false
            this.sp_info.type = 1 //默认显示用户
            // this.$socket.close()
            this.$emit('tokenStatus', [])
        },
    }
}
</script>