<template>
    <!-- <nav v-if="isCategory" aria-label="Site Navigation" class="UYr2M fWRC1 kRlHr">
        <div class="Kv5KD"> -->
    <ul v-if="isCategory" class="h2head-ul">
        <li v-for="(item, i) in categoryList" :key="i">
            <div class="CaTY6 Z7O5e tuUGG RTmqg tuUGG RTmqg">{{ item.name }}</div>
            <NavMenu :category="item.category"></NavMenu>
        </li>
    </ul>
    <!-- </div>
    </nav> -->
</template>
<script>
import NavMenu from '@/components/base/NavMenu.vue'
export default {
    name: "CategoryVue",
    components: {
        NavMenu
    },
    props: {
        isCategory: {
            type: Boolean,
            default: true
        }
    },

    data() {
        return {
            //动态增加
            categoryList: [],
        }
    },
    mounted() {
        //获取分类
        if (this.isCategory) {
            this.getCategoryList()
        }
    },
    methods: {

        // 分类
        getCategoryList() {
            const that = this
            this.$api.category.getCategoryList({ type: 2 }).then((res) => {
                that.categoryList = res.data
            })
        },
        //点击分类
        onCateClick(item) {
            this.onMoreItemLeave(item)
            this.ondYMouseLeave()
            this.$router.push({ name: 'category', query: { id: item.id, level: item.level } })
        },
        onChildCateClick(data) {
            this.onMoreItemLeave(this.categoryList[data.i])
            this.ondYMouseLeave()
            this.$router.push({ name: 'category', query: { id: data.item.id, level: data.item.level } })
        },
        onMouseMove(e) {
            console.log(e)
            // console.log(this.$refs.MoreItem)
            // //点击空白处
            // if (!this.$refs.MoreItem.contains(e.target) && !this.$refs.MoreItem1.contains(e.target)) {
            //   this.isd0 = false
            // }else{
            //   this.isd0 = true
            // }
        },
        onRenderCustom(swiper, current, total) {
            this.total = total
            this.current = current
        },
        //遮罩
        ondYMouseEnter() {
            this.isdY = true
        },
        ondYMouseLeave() {
            this.isdY = false
        },
        // 分类
        onCategoryEnter(item, i) {
            // console.log('sort', i, this.$refs['category' + i])
            this.isSearch = false
            item.show = true
            this.ondYMouseEnter()
            if (this.$refs['category' + i]) {
                this.dYHeight = this.$refs['category' + i][0].$el.offsetHeight + 56
                if (this.isd0) {
                    this.dYHeight += 52
                }
            }

            this.dYHeight = this.dYHeight + 'px'
        },
        onCategoryLeave(item) {
            item.show = false
        },
        //更多分类
        onMoreItemEnter(item, i) {
            this.isd0 = true
            if (item) {
                this.onCategoryEnter(item, i)
            } else {
                this.ondYMouseLeave()
            }
        },
        onMoreItemLeave(item) {
            this.isd0 = false
            if (item) {
                this.onCategoryLeave(item)
            }
        }
    }
}
</script>
