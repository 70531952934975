<template>
    <!-- 在线客服 -->

    <!-- 下拉框 -->
    <aside class="GX7lR -SBnes GsMenu"
        style="top: 100%; left: -30px; padding-top: 16px; z-index: 1; position: absolute">
        <div class="TlLMR">
            <div class="ckrPL">
                <section class="yFHT9">
                    <!-- 24小时客服 -->
                    <h2 v-if="isLogin" class="fOz0y dls-boa17d" style="margin-top: 0">
                        <a class="lcFyR tuUGG RTmqg">{{ $t('_zdy._24zrkf') }}</a>
                    </h2>
                    <ul v-if="isLogin">
                        <li>
                            <CommentOutlined style="margin-right: 13px; font-size: 20px" />
                            <router-link
                                :to="{ name: sp_info.type == 1 ? 'message' : 'storeMessage', query: { customer: 'agent' } }"
                                class="KCBwR lcFyR tuUGG RTmqg"> {{ $t('_n2._jscldd') }} </router-link>
                        </li>
                        <li v-if="sp_info.whatsapp">
                            <IconWhatsApp style="margin-right: 13px; font-size: 20px" />
                            <a :href="sp_info.whatsapp ? 'https://wa.me/' + sp_info.whatsapp : 'javascript:'"
                                :target="sp_info.whatsapp ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg">
                                Whats app </a>
                        </li>
                        <li v-if="sp_info.telegram">
                            <IconTelegram style="margin-right: 13px; font-size: 20px" />
                            <a :href="sp_info.telegram ? 'https://t.me/' + sp_info.telegram : 'javascript:'"
                                :target="sp_info.telegram ? '_blank' : ''" class="KCBwR lcFyR tuUGG RTmqg">
                                Telegram </a>
                        </li>
                        <li v-if="sp_info.line">
                            <IconLine style="margin-right: 13px; font-size: 20px" />
                            <a :href="sp_info.line" :target="sp_info.line ? '_blank' : ''"
                                class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                        </li>
                        <li v-else-if="sp_info.line_qrcode" style="position: relative">
                            <div class="kdiso">
                                <div style="display: flex">
                                    <IconLine style="margin-right: 13px; font-size: 20px" />
                                    <a href="javascript:" class="KCBwR lcFyR tuUGG RTmqg"> Line </a>
                                </div>
                                <div class="line-box" style="padding: 10px; border: 1px dashed #ccc">
                                    <img :src="sp_info.line_qrcode" alt="line" style="width: 100%" />
                                </div>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
        </div>
        <div class="eLxL5" style="inset: 0px auto auto 40px">
            <div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div>
        </div>
    </aside>

</template>

<script>
export default {
    name: "CustomVue",
    data() {
        return {
            //是否登录
            isLogin: false,
            //昵称
            sp_info: {
                avatar: '',
                nickname: '',
                type: 1
            },
        }
    },
    created() {
        //自动登录
        this.tokenStatus()
    },
    methods: {
        tokenStatus() {
            this.isLogin = this.$common.isLogin()
            if (this.isLogin) {
                //获取基本
                this.sp_info = this.$common.getInfo()
            }
        },
    }
}
</script>