export default {
  'nav.account': 'Персональный центр',
  'nav.language': 'Упрощенный китайский',
  'signup.success': 'Отправить успешно',

  //ошибка
  'contact.empty': 'Контактная информация не может быть пустой',
  'phone.country.empty': 'Префикс телефона не может быть пустым',
  'phone.empty': 'Телефон не может быть пустым',
  'email.valid': 'Пожалуйста, введите действительный адрес электронной почты',

  // база
  'base.success': 'Успех',
  'base.save': 'Сохранить',
  'base.slide': 'Чтобы проверить, потяните ползунок',
  'base.send.code': 'Отправить код подтверждения',
  'base.send.time': '({time}) повторно отправить код подтверждения',
  'base.send.success': 'Отправить успешно',
  'base.view.more': 'Посмотреть больше',
  'base.Language': 'Язык',
  'base.previous': 'Предыдущая страница',
  'base.next': 'Следующая страница',
  'base.total.page': 'Всего страниц {page}',
  'base.go.to.page': 'Перейти на страницу',
  'base.go': 'Перейти',
  'base.followers': 'Подписчики',
  'base.follow': 'Подписаться',
  'base.following': 'Подписано',
  'base.visit.store': 'Просмотреть магазин',
  'base.contact': 'Свяжитесь с нами',
  'base.delivery': 'Расчетное время доставки: 1-7 дней',
  'base.search': 'Поиск',
  'base.search.text': 'Введите ключевые слова для поиска',
  'base.server': 'Онлайн-обслуживание клиентов',

  //индекс
  'text.categories.title': 'Категория товара',
  'text.welcome': 'Добро пожаловать за покупками!',
  'text.wishlist': 'Список желаний',
  'text.account': 'Персональный центр',
  'text.orders': 'Заказы',
  'text.messages': 'Сообщения',
  'text.suggest.title': 'Эксклюзивное предложение',
  'text.suggest.content': 'Только для новых глобальных премиум-пользователей!',
  'text.super.deals': 'Лучшие товары по невероятным ценам.',

  //зарегистрироваться
  'signup.register': 'Регистрация',
  'signup.signin': 'Войти',
  'signup.store.signin': 'Бизнес-логин',
  'signup.sign.out': 'Выйти',
  'signup.email': 'Адрес электронной почты',
  'signup.password': 'Пароль',
  'signup.qr.password': 'Подтвердите пароль',
  'signup.confirm.password': 'Подтвердите пароль',
  'signup.forgot.password': 'Забыли пароль',
  'signup.invitation': 'Код приглашения',
  'signup.char': 'Персонаж',
  'signup.contains': 'Содержит цифры, буквы или символы',
  'signup.qr.contains': 'Два пароля несовместимы',
  'signup.create.account': 'Создать аккаунт',
  'signup.agree': 'Создать аккаунт, вы согласны',
  'signup.member.agreement': 'Членское соглашение',
  'signup.and': 'и',
  'signup.privacy.policy': 'Политика конфиденциальности',
  'signup.email.code': 'Подтверждение электронной почты',
  'signup.last.step': 'Последний шаг',
  'signup.send.email': 'Пожалуйста, введите 4-значный код, отправленный на {email}',
  'signup.modify.email': 'Изменить электронную почту',
  'signup.verify.email': 'Электронная почта для подтверждения',
  'signup.have.store': 'Есть аккаунт продавца',
  'signup.goto.signin': 'Бизнес-логин',
  'signup.no.store': 'Нет аккаунта продавца',
  'signup.goto.store': 'Зарегистрироваться в магазине',
  'signup.next': 'Далее',
  'signup.your.email': 'Ваш адрес электронной почты',
  'signup.code.text': 'Код подтверждения',
  'signup.submit.signup': 'Зарегистрируйтесь сейчас',
  'signup.smrz': 'Аутентификация по реальному имени',
  'signup.derb': 'Второй шаг',
  'signup.qsrxm': 'Пожалуйста, введите название документа',
  'signup.qsrhm': 'Пожалуйста, введите идентификационный номер',

  //забыл
  'forgot.title': 'Сбросить пароль',
  'forgot.btn.check': 'Подтвердить электронную почту',
  'forgot.reset.now': 'Сбросить сейчас',

  //магазин
  'store.info.open': 'Открытая {y} неделя',

  // нижний колонтитул
  'footer.great.value': 'Отличное значение',
  'footer.great.value.desc': 'Мы предлагаем конкурентоспособные цены на более чем 100 миллионов товаров.',
  'footer.shopping': 'Покупки по всему миру',
  'footer.shopping.desc': 'Мы доставляем товары более чем в 200 стран и регионов, а наш веб-сайт доступен на 7 языках.',
  'footer.safe.payment': 'Безопасный платеж',
  'footer.safe.payment.desc': 'Оплачивайте самыми популярными и безопасными способами оплаты в мире.',
  'footer.shop.with.confidence': 'Покупайте с уверенностью',
  'footer.shop.with.confidence.desc': 'Наша политика защиты покупателей распространяется на весь процесс покупки.',
  'footer.help.center': 'Справочный центр',
  'footer.help.center.desc': 'Помощь 24/7 для создания комфортных покупок.',
  'footer.terms.conditions': 'Условия и положения',
  'footer.return.policy': 'Политика возврата',
  'footer.support.policy': 'Политика поддержки',
  'footer.privacy.policy': 'Политика конфиденциальности',
  'footer.be.seller': 'Стать продавцом',
  'footer.apply.now': 'Подать заявку сейчас',
  'footer.stay.connected': 'Оставайтесь на связи',

  'footer.about.us': 'О нас',
  'footer.about.company': 'Профиль компании',
  'footer.about.video': 'Введение видео',
  'footer.contact': 'Контакт',

  'footer.my.account': 'Мой аккаунт',
  'footer.my.logout': 'Выйти',
  'footer.my.order': 'История заказов',
  'footer.my.wish': 'Мой список желаний',
  'footer.my.join': 'Стать партнером',
  'footer.email': 'Электронная почта',
  'footer.gfemail': 'Официальный адрес электронной почты',
  'footer.fwemail': 'Служебная электронная почта',
  'footer.address': 'Адрес',

  'apply.success': 'Приложение выполнено успешно',
  'apply.success.desc': 'Применение успешно, войдите в учетную запись',

  // категории
  'category.title': 'Связанная категория',
  'category.all': 'Все категории',

  //деталь
  'detail.store.home': 'Главная страница магазина',
  'detail.sale.items': 'Список товаров',
  'detail.recommend': 'Рекомендуемый продукт',
  'detail.orders': 'Продажи',
  'detail.quantity': 'Количество',
  'detail.pieces.available': 'Доступно',
  'detail.delivery': 'Доставка',
  'detail.free.shipping': 'Бесплатная доставка',
  'detail.estimated.delivery': 'Расчетная доставка',
  'detail.days': 'Дней',
  'detail.buy.now': 'Купить',
  'detail.add.to.cart': 'Добавить в корзину',
  'detail.buyer.protection': 'Защита покупателя',
  'detail.money.guarantee': 'Гарантия возврата денег',
  'detail.refund.desc': 'Получите полный возврат средств, если товар не соответствует описанию или не доставлен',
  'detail.description': 'Описание товара',
  'detail.customer.reviews': 'Отзывы клиентов',
  'detail.specifications': 'Характеристики продукта',
  'detail.top.selling.products': 'Самые продаваемые товары',
  'detail.recommended.for.you': 'Рекомендуется для вас',
  'detail.sold': 'Продажи',
  'detail.receipt': 'Подтвердить получение',
  'detail.receipt.title': 'Вы уверены, что получили этот заказ?',
  'detail.receipt.content': 'После подтверждения транзакция заказа завершена',
  'detail.comment': 'Комментарий',
  'detail.refund.title': 'Подтвердить заявку на возврат средств',
  'detail.refund.content': 'После подтверждения заказ будет возвращен',
  'detail.sqtk': 'Заявка на возврат средств',

  //подтверждать
  'confirm.shipping.address': 'Адрес получателя',
  'confirm.change': 'Изменить',
  'confirm.payment.methods': 'Способы оплаты',
  'confirm.summary': 'Расчет',
  'confirm.total.item.costs': 'Всего по позиции',
  'confirm.total': 'Всего',
  'confirm.checkout': 'Оформить заказ',
  'confirm.place.order': 'Разместите заказ сейчас',
  'confirm.pay.now': 'Оплатить сейчас',
  'confirm.order.desc': "Нажав 'Разместить заказ', я подтверждаю, что прочитал и подтверждаю",
  'confirm.order.policy': 'Все условия и правила',
  'confirm.payment': 'Global Shopping обеспечивает безопасность вашей информации и платежей',

  // адрес
  'address.title': 'Адрес получателя',
  'адрес.по умолчанию': 'по умолчанию',
  'address.edit': 'Редактировать',
  'address.delete': 'Удалить',
  'address.new': 'Создать новый адрес',
  'address.contact': 'Контакт',
  'адрес.адрес': 'Адрес',
  'address.phone': 'Номер телефона',
  'address.set.default': 'Установить по умолчанию',
  'address.confirm': 'Подтвердить',
  'address.cancel': 'Отмена',
  'address.del.title': 'Подтвердить удаление адреса?',
  'address.del.content': 'Вы уверены, что хотите удалить этот адрес доставки?',

  'payment.method': 'Способ оплаты',
  // Корзина
  'shopping.title': 'Корзина',
  'shopping.back.buy': 'Вернуться к специальным покупкам',
  'shopping.start.shopping': 'Начать покупки',

  // член
  'member.account': 'Персональный центр',
  'member.overview': 'Обзор',
  'member.orders': 'Заказы',
  'member.payment': 'Мой кошелек',
  'member.address': 'Адрес доставки',
  'member.wishlist': 'Список желаний',
  'member.followlist': 'Список подписчиков',
  'member.message.center': 'Центр сообщений',
  'member.setting': 'Личные настройки',
  'member.shop.info': 'Информация о магазине',
  'member.shop.setting': 'Настройки магазина',
  'member.order.notify': 'Уведомление о новом заказе',
  'member.order.tips': 'Отправляя письмо на адрес электронной почты аккаунта продавца',

  // член.заказ
  'member.order.title': 'Мой заказ',
  'member.order.view.all': 'Просмотреть все',
  'member.order.all': 'Все',
  'member.order.unpaid': 'Не оплачено',
  'member.order.paid': 'Оплачено',
  'member.order.procurement': 'В ожидании закупки',
  'member.order.seller.paid': 'Куплено',
  'member.order.processing': 'В ожидании',
  'member.order.shipped': 'отправлено',
  'member.order.completed': 'Завершено',
  'member.order.refund': 'Возмещено',
  'member.order.all.time': 'Все',
  'member.order.empty': 'Пока нет заказов',
  'member.order.date': 'Дата',
  'member.order.purchase.date': 'Покупка',
  'member.order.cpoy': 'Копировать',
  'member.order.id': 'Заказ',
  'member.order.detail': 'Детали заказа',
  'member.order.logistics': 'Логистическая информация',

  // участник.оплата
  'member.payment.title': 'Мой кошелек',
  'member.wallet.balance': 'Баланс кошелька',
  'member.crypto.recharge': 'Пополнить',
  'member.crypto.withdrawal': 'Вывод средств',
  'member.crypto.bank': 'Банковская карта',
  'member.wallet.record': 'Запись депозита монет',
  'member.bankcard.record': 'Запись банковской карты',
  'member.withdrawal.record': 'Запись о выходе',
  'member.income.record': 'Отчет о доходах',
  'member.transaction.record': 'Запись транзакции',
  'member.wallet.freeze': 'Заморозить средства',
  'member.wallet.profit': 'Расчетный доход',

  // перезарядка
  'recharge.currency': 'Валюта',
  'recharge.protocol': 'Протокол',
  'recharge.qrcode': 'QR-код',
  'recharge.address': 'Адрес кошелька',
  'recharge.copy': 'Скопировать адрес',
  'recharge.ok.text': 'Подтверждение',
  'recharge.cancel.text': 'Отмена',

  //банк
  'bank.recharge.title': 'Пополнение банковской карты',
  'bank.title': 'Название банка',
  'bank.name': 'Имя получателя платежа',
  'bank.account': 'Счет получателя',
  'bank.routing': 'Маршрутный номер банка',
  'bank.code': 'Код банка',
  'bank.bankname': 'Название банка',
  'bank.bankaddress': 'Адрес банка',
  'bank.ok.text': 'Далее',
  'bank.ok.prev': 'Предыдущий шаг',
  'bank.submit': 'Отправить сейчас',
  'bank.amount': 'Сумма пополнения',
  'bank.amount.desc': 'Пожалуйста, введите сумму пополнения',
  'bank.type': 'Тип',
  'bank.usd': 'доллары США',
  'bank.eur': 'евро',
  'bank.receipt.number': 'Номер квитанции',
  'bank.receipt.number.desc': 'Пожалуйста, введите номер квитанции об операции',
  'bank.credential.picture': 'Изображение учетных данных',
  'bank.credential.picture.desc': 'Пожалуйста, загрузите изображение учетных данных',
  'bank.remark': 'Примечание',
  'bank.upload': 'Загрузить картинку',
  'bank.text.title': 'Заметки',
  'bank.text.t1': '1. Примечания к переводу не нужны, оставьте поле пустым',
  'bank.text.t2': '2. Для домохозяйств Гонконга выберите Гонконг вместо Китая',
  'bank.text.t3': '3. Американский денежный перевод, в будние дни переводить европейский денежный перевод до 15:00, денежный перевод доступен в рабочее время банка!',
  'bank.text.t4': '4. Время платежа - T+1, а последний платеж - T+3 в Европе',
  'bank.text.t5': '5. Сообщите платформе перед переводом денег, чтобы подтвердить, доступен ли счет. Если счет закрыт, компенсация не выплачивается.',
  'bank.text.t6': '6. Депозит с помощью банковской карты. Информация об обслуживании клиентов имеет преимущественную силу.',

  //банклист
  'bank.list.title': 'Название банка',
  'bank.list.code': 'Код банка',
  'bank.list.amount': 'Сумма перевода',
  'bank.list.number': 'Номер квитанции',
  'bank.list.img': 'Изображение ваучера',
  'bank.list.status': 'Статус',
  'bank.list.time': 'Время',
  'bank.list.status1': 'На рассмотрении',
  'bank.list.status2': 'Пройдено',
  'bank.list.status3': 'Отклонено',

  // Снятие
  'withdrawal.address.desc': 'Пожалуйста, введите адрес кошелька!',
  'withdrawal.number': 'Номер',
  'withdrawal.real.number': 'фактическое прибытие',
  'withdrawal.number.desc': 'Пожалуйста, введите сумму вывода!',
  'withdrawal.btn.all': 'Все',
  'withdrawal.balance': 'Баланс',
  'withdrawal.commission': 'Комиссия',
  'withdrawal.actual.amount': 'Счет',
  'withdrawal.notice': 'Уведомление',
  'withdrawal.notice.text': 'Перед передачей убедитесь, что информация об адресе получения верна. После того, как активы будут переданы, они не могут быть возвращены.',
  'withdrawal.notice.content': '{name}({cp_name}) комиссия: текущая рыночная стоимость {fee_rate}%/транзакция, минимальный стандарт: {fee_min} {name}/транзакция',
  'withdrawal.submit': 'Отправить',
  'withdrawal.choice': 'Выберите криптовалюту',
  'withdrawal.yzm': 'Код подтверждения',
  'withdrawal.fs': 'Отправить',
  'withdrawal.qsryzm': 'Пожалуйста, введите код подтверждения электронной почты',

  // получаем
  'recive.method': 'Протокол',
  'recive.amount': 'количество',
  'recive.address': 'Адрес',
  'recive.date': 'Время',
  'recive.status': 'Статус',
  'recive.create.at': 'Время транзакции',
  'recive.type': 'Тип',
  'recive.befor': 'Перед транзакцией',
  'recive.balance': 'Баланс',
  'recive.freeze': 'Заморозить',
  'recive.review': 'Рецензирование',
  'recive.success': 'Пройдено',
  'recive.reject': 'Отклонено',

  // реклама
  'advertise.title': 'Центр продвижения',
  'advertise.shop.title': 'Реклама магазина',
  'advertise.shop.status': 'Статус',
  'advertise.shop.expired': 'Просрочено',
  'advertise.shop.promotion': 'Продвижение',
  'advertise.shop.expire.date': 'Дата истечения срока действия',
  'advertise.shop.renew': 'Продлить рекламу',
  'advertise.shop.payable': 'Сумма к оплате',
  'advertise.shop.explanation': 'Объяснение рекламы',
  'advertise.shop.text': 'Участвуйте в рекламном продвижении платформы, повышайте узнаваемость магазина и продвигайте транзакционные заказы',
  'advertise.shop.paynow': 'Продлить сейчас',
  'advertise.shop.modal.title': 'Подтвердить платеж',
  'advertise.shop.modal.desc': 'Подтвердите оплату промоакции',
  'advertise.shop.modal.btn': 'Подтвердить платеж',

  //Список желаний
  'wishlist.title': 'Список желаний',
  'wishlist.delete': 'Удалить',
  'wishlist.orders': 'Продажи',

  //Подписаться на список
  'followlist.title': 'Список подписчиков',
  'followlist.delete': 'Удалить',
  'followlist.follow': 'Подписаться',

  // магазин
  'store.dashboard': 'Панель инструментов',
  'store.products': 'Товары',
  'store.products.list': 'Список товаров',
  'store.products.reviews': 'Отзывы о товарах',
  'store.orders': 'Заказы',
  'store.wallet': 'Кошелек',
  'store.message': 'Центр сообщений',
  'store.setting': 'Настройки',
  'store.order.total.profit': 'Расчетный общий операционный доход',

  //панель приборов
  'dashboard.store.hour.views': 'Посещения в режиме реального времени',
  'dashboard.store.today.views': 'Сегодняшние просмотры',
  'dashboard.product.total': 'Общий продукт',
  'dashboard.product.today': 'Добавлено сегодня',
  'dashboard.order.total': 'Общая сумма заказа',
  'dashboard.sales.total': 'Расчетный оборот',
  'dashboard.sales.real': 'Реальные продажи',
  'dashboard.sales.pay': 'Общая выплата',
  'dashboard.sales.profit': 'Общая прибыль',
  'dashboard.commission.total': 'Общий доход',
  'dashboard.commission.today': 'Сегодняшний доход',
  'dashboard.order.sales': 'Продажи товаров',
  'dashboard.sales.list': 'Рейтинг продаж',
  'dashboard.goods.cate.rate': 'Соотношение категорий товаров',
  'dashboard.goods.wish': 'Рейтинг лайков товаров',
  'dashboard.january': 'Январь',
  'dashboard.february': 'Февраль',
  'dashboard.march': 'Март',
  'приборная панель. апрель': 'апрель',
  'dashboard.may': 'Май',
  'приборная панель. июнь': 'июнь',
  'приборная панель. июль': 'июль',
  'dashboard.august': 'Август',
  'dashboard.september': 'Сентябрь',
  'dashboard.october': 'Октябрь',
  'dashboard.november': 'ноябрь',
  'dashboard.december': 'декабрь',

  // продукты
  'products.add.new': 'Добавить новый товар',
  'products.add.from.warehouse': 'Добавить товары со склада',
  'products.delete': 'Удалить',
  'products.add': 'Добавить',
  'products.table.img': 'Изображение',
  'products.table.name': 'Название продукта',
  'products.table.category': 'Категория',
  'products.table.wish': 'нравится',
  'products.table.stock': 'Склад',
  'products.table.price': 'Цена',
  'products.table.profit': 'Прибыль',
  'products.table.action': 'Действие',
  'products.search.category': 'Поиск по категории',
  'products.back.product': 'Вернуться к списку товаров',
  'products.total': 'Всего продуктов',
  'products.yes': 'Да',
  'products.no': 'Отмена',
  'products.batch.add': 'Групповое добавление',
  'products.ask.add': 'Вы уверены, что хотите добавить товары?',
  'products.batch.delete': 'Пакетное удаление',
  'products.ask.delete': 'Вы уверены, что хотите удалить продукт?',
  'products.top': 'Топ',
  'products.syzd': 'Верх главной страницы',
  'products.zdwz': 'Верхняя позиция',
  'products.t1': 'Продвижение',
  'products.t2': 'Предпочитаемый',
  'products.t3': 'Бутик',
  'products.t4': 'нет',

  // отзывы
  'reviews.title': 'Отзывы о товарах',
  'reviews.product.name': 'Товар',
  'reviews.user.name': 'Пользователь',
  'reviews.star': 'Рейтинг',
  'reviews.comment': 'Просмотреть контент',
  'reviews.sku': 'Характеристики товара',
  'reviews.imgs': 'Фотогалерея',
  'reviews.created': 'Дата',

  // магазин. заказ
  'store.order.purchase': 'Купить сейчас',
  'store.order.purchase.desc': 'Вы уверены, что хотите оплатить этот товар?',
  'store.order.purchase.yes': 'платить немедленно',
  'store.order.purchase.no': 'Отмена',
  'store.order.desc': 'Для покупки этого заказа требуется оплата, и вы получите преимущества после того, как заказ будет выполнен.',
  'store.order.no': 'Номер заказа',
  'store.order.number': 'Количество',
  'store.order.buyer': 'Покупатель',
  'store.order.total': 'Общая сумма',
  'store.order.will.earning': 'Заработок',
  'store.order.profit': 'Прибыль',
  'store.order.dividends': 'Дивиденды',
  'store.order.payment.status': 'Статус оплаты',
  'store.order.seller.buy.status': 'Статус покупки',
  'store.order.status': 'Статус заказа',
  'store.order.date': 'Дата заказа',
  'store.order.purchase.date': 'Дата покупки',
  'store.order.action': 'Действие',
  'store.order.purchase.amount': 'Сумма покупки',

  //доход
  'income.create.at': 'Рекордное время',
  'income.order.sn': 'Номер заказа',
  'income.realpay': 'Сумма заказа',
  'доход.прибыль': 'доход',

  //Параметр
  'setting.avatar': 'Бизнес-аватар',
  'setting.upload': 'Загрузить',
  'setting.shop.name': 'Название магазина',
  'setting.shop.phone': 'Контактный телефон',
  'setting.shop.address': 'Адрес магазина',
  'setting.shop.save': 'Сохранить',
  'setting.upload.pic': 'Загрузить картинку',
  'setting.send.pic': 'Отправить картинку',

  // бесселлер
  'beseller.title': 'Подать заявку, чтобы стать продавцом',
  'beseller.account': 'Информация об учетной записи',
  'beseller.store': 'Информация о магазине',
  'beseller.store.name': 'Название магазина',
  'beseller.store.address': 'Адрес магазина',

  // сохранить дом
  'store.home.title': 'Настройки домашней страницы',
  'store.home.topimg': 'Главное изображение',
  'store.home.banner': 'Карусель',
  'store.home.up3': 'Примечания: не менее 3 загрузок',
  'store.upload.more': 'Загрузить больше изображений',
  'store.home.columns': 'Колонки',
  'store.home.bgimg': 'Фоновое изображение',
  'store.goods.remark': 'Примечание: 5 товаров в строке, максимум 10 товаров в разделе',
  'store.home.select': 'Выберите товар',
  'store.home.add': 'Добавить столбец',

  //персональная информация
  'setting.update.user': 'Личная информация',
  'setting.update.info': 'Изменить информацию',
  'setting.user.avatar': 'Аватар пользователя',
  'setting.user.nickname': 'Псевдоним пользователя',
  'setting.user.nickname.desc': 'Пожалуйста, введите псевдоним пользователя',

  'setting.safe.title': 'Информация о безопасности',
  'setting.user.passwd': 'Изменить пароль',
  'setting.passwd.title1': 'Старый пароль',
  'setting.passwd.desc1': 'Пожалуйста, введите старый пароль',
  'setting.passwd.title2': 'Новый пароль',
  'setting.passwd.desc2': 'Пожалуйста, введите новый пароль',
  'setting.passwd.title3': 'Подтвердите пароль',
  'setting.passwd.desc3': 'Пожалуйста, введите пароль для подтверждения',
  'setting.invitation': 'Пригласить друзей',
  'setting.copy': 'Копировать',

  'adv.add.products': 'Добавить рекламные товары',
  'adv.list.title': 'Продвижение товара',
  'adv.type': 'Тип продвижения',
  'adv.begin.time': 'Время акции',
  'adv.end.time': 'Время окончания',
  'adv.status': 'Статус',
  'adv.status.s1': 'Продвижение',
  'adv.status.s2': 'Конец',
  'adv.add.back': 'Вернуться в продвигаемый список',
  'adv.recharge': 'Пополнить бонусные баллы',
  'adv.select.goods': 'Выбрать товары',
  'adv.select.btn': 'Рекламируемые товары',
  'adv.recharge.title': 'Пополнить бонусные баллы',
  'adv.recharge.balance': 'Баланс баллов',
  'adv.point': 'точка',
  'adv.point.rate': 'Соотношение баллов',
  'adv.recharge.amount': 'Сумма пополнения',
  'adv.recharge.input.amount': 'Введите сумму пополнения',
  'adv.select.mod': 'Выбрать панель',
  'adv.mod1': 'Рекомендуемые столбцы',
  'adv.mod2': 'Предпочтительный столбец',
  'adv.mod3': 'Горячая колонка продаж',
  'adv.mod4': 'Отличный список',
  'adv.mod5': 'Список классификаций',
  'adv.mod6': 'Подробное продвижение',
  'adv.ge': 'количество',
  'adv.xs': 'час',
  'adv.xzbk': 'Выбрать номер',
  'adv.syme': 'Оставшаяся квота',
  'adv.tgsc': 'Длительность акции',
  'adv.tgjg': 'Стоимость акции',
  'adv.syye': 'Текущий баланс',
  'adv.tgye': 'Баланс продвижения',
  'adv.yj1': 'ожидается',
  'adv.yj2': 'Начать продвижение',
  'adv.pay.point': 'Очки потребления',

  'wallet.ykcsxf': 'Плата за обработку была вычтена',
  'wallet.sxf': 'Плата за обслуживание',
  'wallet.zxje': 'Минимальная сумма',
  'wallet.24hxe': '24-часовой лимит',
  'wallet.desc.text': 'Ни при каких обстоятельствах чиновник не попросит вас перевести деньги на счет и не запросит у вас код подтверждения. Не участвуйте в незаконных действиях, таких как покупка от имени других лиц, отмывание денег и незаконный сбор средств, а также остерегайтесь онлайн-мошенничества',
  'wallet.cunbi.text': 'Вы можете перевести {name} только на этот адрес, другие активы не будут получены',

  'credit.title': 'Кредитный лимит',
  'credit.ed': 'Квота',
  'credit.hk': 'Погашение',
  'credit.kyye': 'Доступный баланс',
  'credit.dqqk': 'Текущая задолженность',
  'credit.hkje': 'Сумма погашения',
  'credit.hkje.desc': 'Пожалуйста, введите сумму погашения',
  'credit.cgje': 'Сумма покупки',
  'credit.qrz': 'Сертификация',
  'credit.srxyj': 'Введите сумму кредитного платежа',

  'store.gzl': 'следить',
  'store.wxz': 'Неограниченно',

  'auth.smrz': 'Аутентификация по реальному имени',
  'auth.rzlx': 'Тип',
  'auth.sfzm': 'Лицевая сторона удостоверения личности',
  'auth.sffm': 'Обратная сторона удостоверения личности',
  'auth.zsxm': 'настоящее имя',
  'auth.zjhm': 'Номер документа',
  'auth.yyzz': 'Бизнес-лицензия',
  'auth.gsmc': 'Название компании',
  'auth.shz': 'Аудит',
  'auth.ytg': 'Пройдено',
  'auth.ybh': 'Отклонено',
  'auth.zt': 'статус',
  'auth.gr': 'Личное',
  'auth.gs': 'Предприятие',
  'auth.ljtj': 'Подать заявку на аутентификацию',
  'auth.wrz': 'Не прошел проверку подлинности',

  'credit.qts': 'Посмотреть описание',
  'store.spss': 'Верхний предел товара',
  'store.dpfh': 'Сохранить дивиденды',
  'store.qydj': 'Уровень акций',
  'магазин.уровень': 'уровень',
  'store.jian': 'кусочки',

  'order.notify.email': 'Из-за контроля рисков электронной почты частая отправка электронных писем с напоминанием о заказе платформы будет перехвачена. Чтобы получать их вовремя, рекомендуется добавить {email} в логин белый список адресов электронной почты',

  'setting.sjxx': 'Информация',
  'setting.smrz': 'Аутентификация',
  'setting.aqzx': 'Безопасность',
  'setting.tzxx': 'Уведомление',
  'setting.dpzx': 'Украшение',
  'setting.sjqy': 'Капитал',
  'setting.sjdjqy': 'Описание преимуществ уровня продавца',

  'setting.fhbky': 'Текущий дивиденд недоступен',
  'setting.spgzyzy': 'Правила и рекомендации магазина',

  'desc.dqqy': 'Текущий капитал',
  'desc.xjqy': 'Подчиненные интересы',
  'desc.sjqy_0': 'Кредитный лимит магазина',
  'desc.sjqy_1': 'Сохранить дивиденды',
  'desc.sjqy_2': 'Количество товаров, которые можно опубликовать',

  'share.yqlb': 'Список приглашенных',
  'share.zmj': 'Всего продавца',
  'share.zdd': 'Общий заказ',
  'share.zsy': 'Общий доход',
  'share.wcl': 'необработанный',
  'share.ycl': 'Обработано',
  'share.ywc': 'Завершено',
  'share.dpmc': 'Название магазина',
  'share.ddl': 'Количество заказа',
  'share.jjsy': 'Доход от брокерских услуг',
  'share.zts': 'Общее количество элементов',

  'chat.state': 'состояние',
  'chat.zx': 'Онлайн',
  'chat.ljz': 'Подключение',

  'bind.wallet.title': 'Адрес для вывода средств',
  'bind.address.title': 'Добавить адрес',
  'bind.bj': 'Изменить',
  'bind.sc': 'Удалить',
  'bind.qrsc': 'Вы уверены, что хотите удалить этот адрес?',
  'bind.qbdz': 'Адрес кошелька',
  'bind.tjsj': 'Добавить время',
  'bind.cz': 'Операция',
  'withdrawal.address.choice': 'Пожалуйста, выберите адрес для вывода!',

  'order.sxsj': 'Эффективное время',
  'order.ljsj': 'вступает в силу немедленно',
  'order.dssj': 'Вступает в силу по времени',

  'store.st.zt': 'Статус магазина',
  'store.st.zc': 'нормальный',
  'store.st.zc.desc': 'Ваш магазин нормальный, продолжайте в том же духе',
  'store.st.yc': 'Исключение',
  'store.st.yc.desc': 'В вашем магазине есть нестандартные заказы или получены жалобы покупателей',
  'store.st.dj': 'Заморозить',
  'store.st.dj.desc': 'У вашего магазина есть определенный операционный риск, и средства заморожены',
  'store.st.jy': 'отключено',
  'store.st.jy.desc': 'Ваш магазин нарушил соглашения и правила, связанные с платформой, и был отключен',

  'task.sy': 'Домашняя страница',
  'task.rwlbo': 'Список задач',
  'task.rwxqo': 'Детали задачи',
  'task.ljcy': 'Участвовать сейчас',
  'task.gdrw': 'Еще задачи',
  'task.rwlb': 'Список задач захвата',
  'task.qbrw': 'Все задачи',
  'task.wcyd': 'Я участвовал',
  'task.qdz': 'Получение заказов',
  'task.ywc': 'Завершено',
  'task.djy': 'для обмена',
  'task.ddje': 'Сумма заказа',
  'task.cyrs': 'Количество участников',
  'task.ewsy': 'Дополнительные преимущества',
  'task.jzsj': 'Крайний срок',
  'task.ckxq': 'Подробнее',
  'task.rwxq': 'Сведения о задаче',
  'task.cylb': 'Список участников',
  'task.qdcyrw': 'Вы уверены, что хотите участвовать в выполнении этой задачи по заказу?',
  'task.sd': 'Да',
  'task.bue': 'нет',

  'task.wdsy': 'Доход',
  'task.wdfh': 'Дивиденды',
  'task.wdqd': 'Получить заказ',
  'task.dd.leixin': 'Тип ордера',
  'task.dd.qdrw': 'Быстрый заказ',
  'task.dd.ptdd': 'Обычный заказ',

  'flow.lltg': 'Продвижение потока',
  'flow.gmll': 'Купить пакет данных',
  'flow.dqtc': 'Текущий пакет',
  'flow.tc1': 'Пакет',
  'flow.sy2': 'оставшийся',
  'flow.tian3': 'день',
  'flow.jlzs': 'Общее количество записей',
  'flow.lltc': 'Пакет Flow',
  'flow.ll': 'поток',
  'flow.gmsj': 'Время покупки',
  'flow.tcjg': 'Стоимость пакета',
  'flow.sjzf': 'фактический платеж',
  'flow.tcsm': 'Описание пакета',
  'flow.tcsm.sm': 'Приобретение этого пакета повысит узнаваемость магазина и привлечет больше клиентов. Для того, чтобы получить больше заказов и увеличить доход магазина. ',

  'flow.tcmc': 'Имя пакета',
  'flow.gmsc': 'Продолжительность покупки',
  'flow.zje': 'Общая сумма',
  'flow.jzsj': 'Крайний срок',
  'flow.gmrq': 'Дата покупки',
  'flow.tian1': 'день',
  'flow.zhou1': 'неделя',
  'flow.yue1': 'месяц',

  'share.dj': 'уровень',

  'share.hz': 'Передача',
  'share.hzd': 'Перевести в',
  'share.qbye': 'баланс кошелька',
  'share.tgje': 'Сумма поощрения',
  'share.hzje': 'Сумма перевода',
  'share.qsrhzje': 'Пожалуйста, введите сумму перевода',

  '_inv._title': 'Описание приглашения',
  '_inv._t1': '1. Приглашение и отношения приглашения',
  '_inv._desc1': 'A приглашает B, B приглашает C, C приглашает D. Самый высокий уровень — 4, если D снова приглашает E, то E не имеет ничего общего с A. ',
  '_inv._t2': '2. Пригласивший получает вознаграждение',
  '_inv._desc2': 'A получает 4% от общей суммы, выполненной по заказу B, A получает 2% от общей суммы, выполненной по заказу C, и A получает 1% от общей суммы, выполненной по заказу D',

  '_index._gm': 'Покупка',
  '_index._sm': 'Описание: Повысьте узнаваемость магазина и привлеките трафик',

  '_n.dlyzc': 'Вход и регистрация',
  '_n.hy4': 'Есть еще 4',
  '_n.ckgd': 'Показать больше',
  '_n._cxhzk': 'Акции и скидки',
  '_n._cwyp': 'Товары для домашних животных',
  '_n._tjsp': 'Специальное предложение',
  '_n._rmfl': 'Популярные категории',
  '_n._tjsp2': 'Рекомендуемые продукты',
  '_n._rxsp': 'Рекомендуемые продукты',
  '_n._rmsp': 'Популярные товары',
  '_n._gdsp': 'Больше товаров',
  '_n._yzsj': 'Качественный торговец',

  '_n._zxsc': 'Интернет-торговый центр',
  '_n._fkyys': 'Оплата и доставка',
  '_n._xsgz': 'Правила продаж',
  '_n._hhjth': 'Обмен и возврат',
  '_n._lxwm': 'Свяжитесь с нами',
  '_n._sczc': 'политика торгового центра',
  '_n._tkytj': 'Правила и условия',
  '_n._tuzc': 'Политика возврата',
  '_n._zczc': 'Политика поддержки',
  '_n._yszc': 'Политика конфиденциальности',
  '_n._scjj': 'Представление о торговом центре',
  '_n._ljgm': 'Купить сейчас',

  '_n2._rxsp': 'Хит продаж',
  '_n2._pl': 'Комментарий',
  '_n2._sjxx': 'Деловая информация',
  '_n2._rhsh': 'Как получить товар',
  '_n2._xsxd': 'Онлайн-заказ',
  '_n2._jscldd': 'Специальная служба поддержки клиентов',
  '_n2._mfps': 'Бесплатная доставка',
  '_n2._2xsfh': 'Молниеносная доставка',
  '_n2._1d5tdd': 'Прибудет в течение 1-5 дней',
  '_n2._tkzd': 'Экспресс-почта',
  '_n2._ckfh': 'Отправка со склада',
  '_n2._zcps': 'Рядом склад',
  '_n2._jsd': 'Точка получения',
  '_n2._qsjps': 'Доставка по всему миру',
  '_n2._thtj': 'Условия возврата',
  '_n2._thtjsm': 'В течение 14 дней с момента покупки вы можете обменять или вернуть товар',
  '_n2._gycp': 'О продукте',
  '_n2._yc': 'Скрыть',
  '_n2._tiao': 'Тяо',
  '_n2._sypl': 'Все комментарии',
  '_n2._lxfk': 'Оставить отзыв',
  '_n2._xzspgg': 'Пожалуйста, выберите характеристики продукта',
  '_n2._qxdl': 'Сначала войдите в систему',
  '_n2._rnsj': 'Как видите',
  '_n2._rx': 'Горячая распродажа',

  '_n3._qrdd': 'Подтвердить заказ',
  '_n3._njsp': '{num} элементов',
  '_n3._ddzf': 'Оплата заказа',
  '_n3._wydlsp': 'Добро пожаловать в магазин',

  '_n3._sjwfgm': 'Продавец не может покупать товары',
  '_n3._sjbnlt': 'Компании не могут общаться в чате',
  '_chat._hc': 'Вывести',

  '_zc._sryxdz': 'Пожалуйста, введите свой адрес электронной почты',
  '_zc._yxgscw': 'Ошибка формата электронной почты',
  '_zc._qsrmm': 'Пожалуйста, введите пароль',
  '_zc._lcmmbxd': 'Два пароля несовместимы',
  '_zc._hdyzwtg': 'Пожалуйста, подтвердите с помощью ползунка',
  '_zc._qsryqm': 'Пожалуйста, введите код приглашения',
  '_zc._yhzh': 'Зарегистрировать учетную запись пользователя',
  '_zc._qzc': 'Перейти к регистрации',
  '_zc._srdpm': 'Пожалуйста, введите название магазина',

  '_zc._gszz': 'Квалификация компании',
  '_zc._ptzz': 'Квалификации, связанные с платформой',

  '_dd._tijd': 'Отправка заказа',
  '_dd._xzdz': 'Пожалуйста, выберите адрес доставки',
  '_dd._zfz': 'Платеж в процессе',

  '_wd._zf': 'платеж',
  '_wd._cz': 'Перезарядка',
  '_wd._txsq': 'Заявка на снятие средств',
  '_wd._ddsy': 'Доход от заказа',
  '_wd._cgzf': 'Купить',
  '_wd._txbh': 'Отклонить',
  '_wd._txtg': 'Пройдено',
  '_wd._ddtk': 'Возврат заказа',
  '_wd._yehz': 'Перенос',
  '_wd._gmll': 'Купить трафик',
  '_wd._zjkc': 'вычет арендной платы',
  '_wd._fk': 'Отлично',
  '_wd._ns': 'Налог',
  '_wd._bzj': 'Маржа',

  '_xxtz._zntz': 'Уведомление сайта',
  '_xxtz._fh': 'возврат',
  '_xxtz._jzgd': 'Загрузить еще',
  '_xxtz._mysj': 'Больше нет',

  '_sjsy._jrdd': 'Сегодняшний заказ',
  '_sjsy._rxse': 'Ежедневные продажи',

  '_sjsy._xh': 'Индекс',

  '_wd._nbjy': 'Внутренний перевод',
  '_wd._qsruid': 'Пожалуйста, введите UID получателя платежа',
  '_wd._je': 'Сумма',
  '_wd._qsrhzje': 'Пожалуйста, введите сумму перевода',
  '_wd._skr': 'Получатель',
  '_wd._nbhzsm': 'Для внутреннего перевода пользователям платформы внимательно проверьте информацию о получателе платежа',

  '_st._spxlphb': 'Рейтинг продаж продукта',
  '_st._czsp': 'Сбросить поиск',

  '_st._pljrgwc': 'Присоединяйтесь ко всем',

  '_sj._yxz': 'Выбрано',
  '_sj._hjzje': 'Общая общая сумма',
  '_sj._zdcr': 'Автоматически вносить депозит',

  '_wd._ddfh': 'Дивиденды',

  '_dd._csqx': 'Отмена заказа',

  '_tx._yhktx': 'Вывод средств из банка',
  '_tx._sryhmc': 'Пожалуйста, введите название банка',
  '_tx._srskrmc': 'Пожалуйста, введите имя получателя платежа',
  '_tx._srskzh': 'Пожалуйста, введите номер платежного счета',
  '_tx._sryhluh': 'Пожалуйста, введите банковский маршрутный номер',
  '_tx._sryhdm': 'Пожалуйста, введите код банка',
  '_tx._sryhdz': 'Пожалуйста, введите адрес банка',
  '_tx._txje': 'Сумма вывода',
  '_tx._srtxje': 'Пожалуйста, введите сумму вывода',
  '_tx._txkc': 'Комиссия за снятие средств {_sxf}%',

  '_yye._yyye': 'Ежемесячные продажи',
  '_yye._ysy': 'Ежемесячный доход',

  '_sj._hjzsy': 'Общий общий доход',

  '_yhdl._dl': 'Войти',
  '_yhdl._zc': 'Создать учетную запись',

  '_zdy._24zrkf': 'Мы здесь, чтобы помочь вам 24 часа в сутки, 7 дней в неделю',
  '_zdy._zhsz': 'Настройки учетной записи',
  '_zdy._mfps': 'В течение ограниченного времени вы можете получить бесплатную доставку в течение двух рабочих дней тысяч товаров в некоторых регионах. ',
  '_zdy._gmsp': 'Покупать новые товары',
  '_zdy._czyh': 'Выгодные предложения, наслаждайтесь любимыми продуктами',
  '_zdy._xsgw': 'Покупки ограничены по времени! Закажите до полудня 12 мая по восточному времени и получите бесплатную доставку. Доставка до вашей двери на специальном автомобиле! ',
  '_zdy._ssgjc': 'Введите поиск по ключевым словам',

  '_zdy._rmjx': 'Ваш лучший выбор',

  '_dd._plcz': 'Пакетная операция',
  '_dd._plcg': 'Массовая закупка',
  '_dd._qgxcgd': 'Пожалуйста, проверьте заказ, который вам нужно приобрести',
  '_dd._yxddbcg': 'Выбранный заказ не требует покупки',
  '_dd._qdyplcgm': 'Вы уверены, что хотите приобрести выбранный заказ оптом? ,Количество:{n}',
  '_dd._qdcg': 'Подтвердить покупку',
  '_dd._qx': 'Отмена',
  '_dd._plcgwc': 'Пакетная покупка завершена, успешна: {s}/{t}',
  '_dd._zzcgz': 'Покупка',
  '_st._czhk': 'Пополнение и погашение',
  '_st._dkje': 'величина займа',
  '_st._dkjl': 'кредитная история',
  '_st._hkfs': 'Погашение',
  '_st._hkje': 'Сумма погашения',
  '_st._jrong': 'Молл Финанс',
  '_st._ljsq': 'подать заявку немедленно',
  '_st._qrshkje': 'Пожалуйста, введите сумму погашения',
  '_st._qsrdkje': 'Пожалуйста, введите сумму кредита',
  '_st._shz': 'на рассмотрении',
  '_st._sqdklx': 'заем',
  '_st._sqhk': 'Подать заявку на погашение',
  '_st._sqhklx': 'погашение',
  '_st._sqjk': 'обращаться за ссудой',
  '_st._sqlx': 'тип',
  '_st._ybh': 'отклонять',
  '_st._yjk': 'Заимствованный',
  '_st._ytg': 'проходить',
  '_st._zzhk': 'Ожидает погашения',

  '_st._dqed': 'Сумма кредита',
  '_st._ccdked': 'Невозможно превысить лимит кредита',
  '_st._tjsqz': 'Заявка отправляется',
  '_st._dklx': 'Интерес',
  '_st._dkll': 'Процентная ставка по кредиту: {n}%',
  '_kdts._ts': 'Открыто {t} дн.',
  '_jltg._tgje': 'Рекламные инвестиции',

  '_st._yqk': 'Задолженность',
  '_st._qb': 'Все',

  '_spxx._spbm': 'код',

  '_scrz._yyzz': 'Бизнес-лицензия',

  '_sjhb._sjbt': 'Купон',
  '_sjhb._kfxd': 'Список купонов',
  '_sjhb._fxlb': 'Список общего доступа',
  '_sjdhb._hbje': 'номинация',
  '_sjdhb._fxzs': 'Общее количество акций',
  '_sjdhb._fxcz': 'Операция',
  '_sjdhb._fxyhj': 'Поделиться',
  '_sjdhb._gqsj': 'Срок действия',
  '_sjdhb._lqsj': 'Время сбора',
  '_sjdhb._lqbm': 'кодировка',
  '_sjdhb._wlq': 'Не получен',
  '_sjdhb._wsy': 'Не используется',
  '_sjdhb._ysy': 'используется',
  '_sjdhb._ylq': 'Получено',
  '_sjdhb._ygq': 'Срок действия истек',
  '_sjdhb._fuzi': 'Копировать',
  '_sjdhb._lqyhj': 'Купон',
  '_sjdhb._lqlqyhj': 'Получить купон',
  '_sjdhb._yhjbm': 'Код купона',
  '_sjdhb._ljlq': 'Получите это сейчас',

  '_sjdhb._hqgdyhj': 'Получите больше купонов для вас',
  '_sjdhb._nddzyj': 'Ваша электронная почта',
  '_sjdhb._dy': 'Подписаться',
  '_sjdhb._zffs': 'Способ оплаты',
  '_sjdhb._zfjs': 'Пользователи Wholesale Global Station приезжают из 112 стран мира и используют для расчетов USDT/ETH/BTC. USDT/ETH/BTC — это метод транзакций без границ, который обеспечивает мгновенные недорогие транзакции по всему миру, без ожидания и международных комиссий. ',
  '_sjdhb._wzbq': 'Авторское право 2023 Wholesale Limited',
}
