<template>
    <div data-v-4e17954c="" class="header" :style="isCategory ? '' : 'height:100px'">
        <div :class="['header-fiexd', isTop ? 'ssd' : '']">
            <div class="header-top flex-between app-container app-xxd">
                <div class="header-logo flex-start">
                    <a href="/">
                        <img src="@/static/tiktok/img/logo.svg" style="height: 48px;width: 350px;">
                    </a>
                </div>
                <div class="header-search"><i class="el-icon-search"></i>
                    <div aria-haspopup="listbox" role="combobox" aria-owns="el-autocomplete-5750"
                        class="el-autocomplete" style="position: relative;">
                        <div class="el-input"><!---->
                            <input type="text" autocomplete="off" :placeholder="$t('_zdy._ssgjc')" v-model="searchVal"
                                @input="onSearchInput" class="el-input__inner">
                        </div>
                        <div v-if="searchVal.length > 0" role="region"
                            class="el-autocomplete-suggestion el-popper search-popper"
                            style="transform-origin: center top; z-index: 2013; width: 367px; position: absolute;  left: 0;"
                            x-placement="bottom-start">
                            <div class="el-scrollbar">
                                <div class="el-autocomplete-suggestion__wrap el-scrollbar__wrap"
                                    style="margin-bottom: -6px; margin-right: -6px;">
                                    <ul class="el-scrollbar__view el-autocomplete-suggestion__list" role="listbox"
                                        id="el-autocomplete-156">
                                        <li id="el-autocomplete-156-item-0" role="option" class="">
                                            <div class="search-content"><!---->
                                                <div class="search-content-history">
                                                    <div class="search-content-history-header flex-between">
                                                        <h1>{{ searchVal }}</h1>
                                                        <div @click="onClearSearch"><i class="el-icon-delete"></i> Clear
                                                        </div>
                                                    </div>
                                                    <div class="search-content-history-list">
                                                        <div class="empty" v-for="(item, i) in searchShop" :key="i"
                                                            @click="onSearchShop(item)"> <span class="LbkFK ajJZu">
                                                                <b>{{ item.name }}({{ item.sid }})</b>
                                                            </span>
                                                        </div>
                                                        <div class="empty" v-if="searchShop.length == 0"> No Data </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <div class="el-scrollbar__bar is-horizontal">
                                    <div class="el-scrollbar__thumb" style="transform: translateX(0%);"></div>
                                </div>
                                <div class="el-scrollbar__bar is-vertical">
                                    <div class="el-scrollbar__thumb" style="transform: translateY(0%);"></div>
                                </div>
                            </div>
                            <div x-arrow="" class="popper__arrow" style="left: 35px;"></div>
                        </div>
                    </div>
                    <button type="button" class="el-button el-button--primary"><!----><!---->
                        <span> Search</span>
                    </button>
                </div>
                <div class="header-user">
                    <div class="flex-start" style="gap: 10px;">
                        <!-- 用户 -->
                        <div v-if="!isLogin" class="no-login TsMenu">
                            <i class="el-icon-user"></i>
                            <div @click="onSignin()" style="margin-right: 20px; cursor: pointer;">
                                {{ $t('_yhdl._dl') }} | {{ $t('_yhdl._zc') }}
                            </div>
                            <UserMenu @tokenStatus="tokenStatus"></UserMenu>
                        </div>
                        <div v-else class="no-login TsMenu" style="margin-right: 20px; cursor: pointer;">
                            <div>
                                <img :src="sp_info.avatar" alt=""
                                    style="width: 30px; height: 30px; border-radius: 50%" />
                            </div>
                            <div class="kB" style="display: flex;">
                                <a class="lHtRS cQ7wR IdMRc RTmqg ng-mob">{{ sp_info.nickname }}</a>
                            </div>
                            <UserMenu @tokenStatus="tokenStatus"></UserMenu>
                        </div>
                        <div class="TsMenu" style="margin-right: 20px; cursor: pointer;"
                            v-if="isLogin && sp_info.type == 1" @click="onGoCart()">
                            <i class="el-icon-goods" style="font-size: 26px;"></i>
                            <div style="position: absolute;left: 9px;top: 8px;">{{ cart }}</div>
                        </div>
                        <div class="TsMenu" style="margin-right: 20px;" v-if="isLogin">
                            <i class="el-icon-headset"></i>
                            <Custom></Custom>
                        </div>
                        <div class="ng-mob" style="margin-right: 20px;">
                            <i class="el-icon-download" style="font-size: 22px;"></i>
                            <Download></Download>
                        </div>
                        <div class="lang-content TsMenu">
                            <Language></Language>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="header-nav" v-if="isCategory">
            <!-- <ul style="display: flex;">
                <li class="active"> Home </li>
                <li class=""> Category </li>
                <li class=""> Product </li>
                <li class=""> Discounts </li>
                <li class=""> Partnership </li>
                <li class=""> Credit Loan Service </li>
            </ul> -->
            <Category :isCategory="isCategory"></Category>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex'
import Custom from "@/components/index/tiktok/Custom.vue"
import UserMenu from './UserMenu.vue';
import Language from './Language.vue';
import Category from './Category.vue';
import Download from './Download.vue'
export default {
    name: "HeadVue",
    components: {
        Custom,
        UserMenu,
        Language,
        Category,
        Download
    },
    props: {
        isCategory: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isTop: false,

            //是否登录
            isLogin: false,

            //昵称
            sp_info: {
                avatar: '',
                nickname: '',
                type: 1
            },

            //购物车
            cart: 0,


            //搜索
            searchVal: '',
            searchShop: [],
            searching: false,

            ajaxMsg: null,
            ajaxTime: 5000,

        }
    },
    created() {
        // console.log(this.$route.query)
        let that = this
        if (this.$route.query.token) {
            let token = that.$route.query.token
            this.onAutoLogin(token)
        } else {
            this.tokenStatus()
        }

        // this.getTopBanner()

        //获取基本
        this.$api.home.getCartNum().then((res) => {
            that.cart = res.data.cart
        })

        //获取基本信息
        this.$store.dispatch('base/getBase', {})
    },
    computed: {
        ...mapState({
            msgCenter: (state) => state.base.msgCenter
        })
    },
    mounted() {
        // 注册监听页面高度
        let that = this
        let Val = 20
        window.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop
            if (that.isTop && scrollTop < Val) {
                that.isTop = false
                console.log(that.isTop, '隐藏')
            }

            if (!that.isTop && scrollTop >= Val) {
                that.isTop = true
                console.log(that.isTop, '显示')
            }
        })
    },
    methods: {
        /**
 * 购物车
 */
        onGoCart() {
            this.$router.push({ name: 'cart', query: { type: 'cart' } })
        },
        onAutoLogin(token) {
            let that = this
            localStorage.setItem(that.$Const.KEY_TOKEN, token)
            that.$api.auth.autoLogin({ token: token }).then((res) => {
                //存入缓存
                let info = res.data
                delete info.token
                localStorage.setItem(that.$Const.KEY_INFO, JSON.stringify(info))
                that.tokenStatus()
                setTimeout(function () {
                    that.$router.push({ name: 'index' })
                }, 1000)
            })
        },
        tokenStatus() {
            console.log("login...2")
            this.isLogin = this.$common.isLogin()
            if (this.isLogin) {
                //获取基本
                this.sp_info = this.$common.getInfo()
            }
        },
        //搜索
        onSearchInput() {
            //通知上级
            console.log(this.searchVal)

            if (this.searchVal.length < 2) {
                this.searchShop = []
                return
            }

            // 搜索符合的店铺名称
            this.searching = true

            let that = this
            this.$api.home.searchShop({ search: this.searchVal }).then((res) => {
                that.searching = false
                that.searchShop = res.data
            })
        },
        onSearchShop(val) {
            this.$router.push({ name: 'shopIndex', query: { id: val.id } })
        },
        onClearSearch() {
            this.searchVal = ''
            this.searchShop = []
        },
    }
}
</script>
<style scoped>
.ssd {
    padding: 0px !important;
    transition: padding 0.5s linear;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 10px;
    height: auto !important;
}
</style>
<style scoped>
@import url("../../../static/tiktok/css/app.7301f093.css");
</style>
<!-- <style scoped src="../../../static/tiktok/css/app.7301f093.css"></style> -->