<template>
    <div class="app-main" style="padding-top: 16px; left: calc(100% - 371px)">
        <div class="app-box" style="padding: 10px">
            <div class="app-download">
                <img src="../../../assets/app_android.png" alt="" />
            </div>
            <div class="app-download">
                <img src="../../../assets/app_ios.png" alt="" />
            </div>
        </div>
        <div class="eLxL5" style="inset: 0px auto auto 340px">
            <div class="oGvoJ" style="top: 8px; transform: rotate(-135deg)"></div>
        </div>
    </div>
</template>
<script>
// import { CloudDownloadOutlined } from '@ant-design/icons-vue'
export default {
    name: "DownloadVue",
    components: {
        // CloudDownloadOutlined,
    },
}
</script>

<style scoped>
.app-download {
    width: 170px;
    height: 170px;
    z-index: 10;
}

.app-download img {
    width: 100%;
    height: 100%;
}
</style>