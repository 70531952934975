<template>
  <div>
    <div class="dbk" :style="'height:' + screenHeight + 'px'">
      <div class="dlc">
        <div class="dlbox">
          <div class="_logo">
            <div class="dlogo">
              <a href="/">
                <img src="@/static/tiktok/img/logo.svg" alt="" />
              </a>
            </div>
          </div>
          <div class="sti">{{ $t('_n3._wydlsp') }}</div>
          <div class="iibox">
            <div class="_K31E">
              <div class="_KOJ">
                <label class="kPs">
                  <input @keyup.enter="onSignin" :placeholder="$t('signup.email')" v-model="params.email" class="kpP"
                    name="promoCode" maxlength="254" autocomplete="off" />
                </label>
                <div class="kpO" @click="onClearEmail()">
                  <div type="submit" role="button" class="kfz 1_3W">
                    <div class="e1Z">
                      <i class="eOS" aria-hidden="true">
                        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                          <use xlink:href="#cross"></use>
                        </svg>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="_K31C">
              <div class="_K31E">
                <div class="_KOJ">
                  <label class="kPs">
                    <input @keyup.enter="onSignin" :placeholder="$t('signup.password')" v-model="params.passwd"
                      type="password" class="kpP" name="promoCode" maxlength="254" spellcheck="false" />
                    <div class="ppu"></div>
                  </label>
                  <div class="kpO" @click="onClearPasswd()">
                    <div type="submit" role="button" class="kfz 1_3W">
                      <div class="e1Z">
                        <i class="eOS" aria-hidden="true">
                          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="eP">
                            <use xlink:href="#cross"></use>
                          </svg>
                        </i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <!-- 忘记密码 -->
              <div class="_K31C">
                <router-link class="Wjl" :to="{ name: 'forgot' }"> {{ $t('signup.forgot.password') }}? </router-link>
              </div>
            </div>
            <div class="_K31C">
              <button class="Dlbtn -disb" @click="onSignin()">
                <span class="bha_1">
                  <div style="vertical-align: inherit">
                    <div style="vertical-align: inherit">{{ $t('signup.signin') }}</div>
                  </div>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'storeSignin',
  data() {
    return {
      screenHeight: document.documentElement.clientHeight,
      screenWidth: document.documentElement.clientWidth,

      params: {
        type: 2,
        email: '',
        passwd: ''
      }
    }
  },
  created() { },
  mounted() {
    // 注册监听页面高度
    let that = this
    window.addEventListener('resize', () => {
      that.screenHeight = document.documentElement.clientHeight
      that.screenWidth = document.documentElement.clientWidth
    })
  },
  beforeMount() {
    // 注册监听页面高度
    window.removeEventListener('resize', this.getHeight)
  },
  methods: {
    //输入框验证
    validParam(tips = false) {
      //邮箱未输入
      if (this.params.email.length <= 0) {
        tips && this.$message.warning('Please enter a valid Email Address')
        return false
      }

      //无效的邮箱
      if (!this.$common.checkEmail(this.params.email)) {
        tips && this.$message.warning('Please enter a valid Email Address')
        return false
      }

      //密码未输入
      if (this.params.passwd.length <= 0) {
        tips && this.$message.warning('Please enter a valid Email Address')
        return false
      }

      return true
    },
    onClearEmail() {
      this.params.email = ''
    },
    onClearPasswd() {
      this.params.passwd = ''
    },
    /**
     * 登录
     */
    onSignin() {
      if (!this.validParam(true)) {
        return
      }
      this.$message.loading({ content: 'loading...', key: 'msg' })

      let that = this
      this.$api.auth
        .login(this.params)
        .then((res) => {
          this.$message.success({ content: res.msg, key: 'msg' })
          //存入缓存token
          localStorage.setItem('sp_token', res.data.token)

          //连接socket
          // this.$socket.open()

          //存入缓存
          let info = res.data
          delete info.token
          localStorage.setItem('sp_info', JSON.stringify(info))

          //回调
          setTimeout(() => {
            that.$router.push({ name: 'index' })
          }, 1000)
        })
        .catch(() => { })
    }
  }
}
</script>

<style>
.dbk {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

._logo {
  padding: 0 16px 16px;
}

.sti {
  font-weight: 500;
  font-size: 28px;
}

.dlc {
  width: 100%;
  max-width: 550px;
  position: relative;
  display: flex;
  justify-content: center;
  flex-shrink: 0;
  margin: auto;
  box-shadow: none;
  background: #fff;
  border-radius: 20px;
  align-content: center;
}

.dlbox {
  padding: 40px 90px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: -50%;
}

.dlogo {
  z-index: 1;
  width: 360px;
  height: 66px;
  border-width: 2px;
  border-color: #fff;
  border-style: solid;
}

.dlogo img {
  width: 100%;
}

.iibox {
  margin-top: 40px;
  width: 100%;
  display: grid;
  gap: 15px;
}

.in-box {
  width: 80%;
  height: 44px;
  background: #a2d2ff;
  border-radius: 11px;
  padding: 5px 15px;
}

.in_in {
  background: none;
  height: 100%;
  font-size: 16px;
}

.bs1 {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
